import React from "react"
import { Link } from "gatsby"

// Components
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Dot from "../../components/dot"

// Styles
import "../../css/pages/work.css"


const ETHSurveyPage = () => (
  <Layout>
    <SEO title="MPX" />
    <section className="ethsurvey-banner work-banner bg-black h-64 py-20 px-4 lg:px-0 text-white">
      <div className="container flex flex-col h-full justify-between">
        <div className="flex flex-col justify-around h-full">
          <h2 className="font-header">Side Project <Dot/></h2>
          <div>
            <h1 className="font-bold">ETH Survey</h1>
            <p className="text-white mt-5 text-shadow">Creating and executing surveys in the blockchain</p>
          </div>
        </div>
      </div>
    </section>
    <div className="container pt-16 px-4 lg:px-0 flex flex-col sm:flex-row justify-between">
      <div className="font-serif flex justify-between sm:justify-start">
        <span className="font-bold font-header">date :</span> &nbsp;&nbsp;<i>September 2018 - Present</i>
      </div>
      <div className="font-serif flex justify-between sm:justify-start mt-4 sm:mt-0">
        <span className="font-bold font-header">Role:</span> &nbsp;&nbsp;<i>Product designer</i>
      </div>
    </div>
    <section className="container pt-16 px-4 lg:px-0">
      <h4 className="font-header mb-5">brief <Dot/></h4>
      <p>ETHSurvey was a project which took birth in a hackathon conducted at ETHIndia. I teamed up with one of my good friends to build a decentralized feedback system which would be incentivized.</p>
			<p>The premise of the hack was simple.</p>
      <ul>
				<li>User can create a simple survey through our easy to use survey builder</li>
				<li>User funds the survey and sets a response limit and expiration</li>
				<li>Any user who takes the survey will get a chunk of the funded amount which is set in the smart contract</li>
				<li>This would encourage users to take surveys, and survey creators to get valid and guaranteed feedback/responses</li>
			</ul>
			<p>The main goal in building this was to educate users about decentralized applications. Us building ETHSurvey would be a simple effort to help users in interacting with the ethereum blockchain and show how seamless it would be with a simple, easy to use front end.
				I self commisioned to build a simple UI and bring the entire idea from concept to life.</p>
    </section>

    <section className="container pt-24 px-4 lg:px-0">
			<h4 className="font-header mb-5">ux flows <Dot/></h4>
      <p> I identified two flows which users will experience</p>
			<p>1. User who wants to create a survey</p>
      <ul>
        <li>Go through survey building process</li>
        <li>Set expiration, responses limit</li>
				<li>Set funding amount</li>
				<li>Confirm and create a web3 transaction with set data returning a unique survey link to be shared to get responses</li>
				<li>A place where user can fetch the responses after the survey expiration</li>
			</ul>
			<p>2. User who wants to take the survey</p>
			<ul>
				<li>Opens the survey link</li>
				<li>Answers all the questions in the survey and submits the transaction which executes the smart contract getting a chunk of funded amount</li>
			</ul>
    </section>

    <section className="container pt-24 px-4 lg:px-0">
			<h4 className="font-header mb-5">wireframes <Dot/></h4>
      <p>I mapped out the entire flow to get an idea of what I'm dealing with.</p>
    </section>
    <section className="container lg text-center my-20 px-4 lg:px-0">
      <img src="https://res.cloudinary.com/ds24tivvl/image/upload/v1568661689/portfolio/j112dmljhljzqvrgssoa.png" alt="ux-flow" className="mb-5" />
      <p className="font-header text-xs">Basic flow of the entire app</p>
    </section>

    <section className="container pt-24 px-4 lg:px-0">
			<h4 className="font-header mb-5">designs <Dot/></h4>
      <p>I created the following designs quickly based on the UX flows ideated.  Each user will be identified with the wallet address</p>
    </section>

    <section className="container lg text-center my-20 px-4 lg:px-0">
      <div className="mb-24">
        <img src="https://res.cloudinary.com/ds24tivvl/image/upload/v1568661623/portfolio/qjpclcuqk2h0nabur16f.png" alt="homepage" className="mb-5" />
        <p className="font-header text-xs">Home page with options to build a survey or view the created survey based on connected wallet</p>
      </div>

      <div className="mb-24">
        <img src="https://res.cloudinary.com/ds24tivvl/image/upload/v1568662012/portfolio/eyjt6n7wdq39i4tf7mdt.png" alt="Survey Creation" className="mb-5" />
        <p className="font-header text-xs">Survey creation with three steps</p>
      </div>
    </section>

    <section className="container px-4 lg:px-0">
      <p>
        We managed to build an initial prototype with the above UI in the hackathon, unfortunately, we did not end up winning anything but got some
        great feedback from the people who checked out the project.
      </p>
    </section>
    <section className="container my-20 px-4 lg:px-0">
      <h4 className="font-header mb-5">re-visiting designs <Dot/></h4>
      <p>
        After the hackathon, we decided to continue this project. I knew there were a lot of UX gaps in the initial version done in the hackathon.
        I eventually created a better version of the entire flow after noticing some obvious flaws in usability.
      </p>
      <p>
        Here were the things which I had to address for re-visiting the designs created during the hackathon:
      </p>
      <ul>
        <li>There was no proper onboarding (checking for MetaMask/web3 connection)</li>
        <li>Improving the survey builder and making it more intuitive</li>
        <li>Homepage educating a brief of what ETHSurvey was about</li>
      </ul>
    </section>

    <section className="container lg text-center my-20 px-4 lg:px-0">
      <div className="mb-56">
        <img src="https://res.cloudinary.com/ds24tivvl/image/upload/v1568662257/portfolio/zp5bgkephbbzeey35urc.png" alt="home page" className="mb-5" />
        <p className="font-header text-xs">Updated home page designs with a bit of eductation to get started with using dapps</p>
      </div>
      <div className="mb-56">
        <img src="https://res.cloudinary.com/ds24tivvl/image/upload/v1569480285/portfolio/authcheck.png" alt="Auth check" className="mb-5" />
        <p className="font-header text-xs">Metamask authentication check done before showing the survey</p>
      </div>
      <div className="mb-56">
        <img src="https://res.cloudinary.com/ds24tivvl/image/upload/v1569315366/portfolio/sgb9n8cuiyizpa0xomv9.png" alt="survey builder" className="mb-5" />
        <p className="font-header text-xs">Easy to use survey builder</p>
      </div>
      <div className="mb-56">
        <img src="https://res.cloudinary.com/ds24tivvl/image/upload/v1569315361/portfolio/ezuizcc5kpflsqmavwme.png" alt="expiration and funding" className="mb-5" />
        <p className="font-header text-xs">Setting the expiration and funding for survey</p>
      </div>
      <img src="https://res.cloudinary.com/ds24tivvl/image/upload/v1569315465/portfolio/ubgocvwuaqvk21srjvr0.png" alt="survey URL" className="mb-5" />
      <p className="font-header text-xs">Survey URL to be shared after submitting the transaction</p>
    </section>

    <section className="container my-16 md:pt-24 flex flex-col px-4 lg:px-0">
      <h4 className="font-header mb-5">final thoughts <Dot/></h4>
      <p>
        The main thing I learned in building a decentralized application in 24 hours which was to timebox certain tasks which helped me to move things rather
        than focus on one aspect and not able to complete the entire project.
        <br/><br/>
        We're still actively (well, kind of passively since we have our day jobs) working on this project and looking forward to deploy it to production soon.
      </p>
    </section>

    <section className="container mt-16 mb-32 flex justify-between px-4 lg:px-0">
      <Link to="/work/mpx" className="line">
        previous project
      </Link>
      <Link to="/work/tikkle" className="line">
        next project
      </Link>
    </section>

  </Layout>
)

export default ETHSurveyPage
